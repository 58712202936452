import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    item: {
        padding: theme.spacing(1, 2),
    },
    itemActive: {
        '& $textContent': {
            borderBottom: `3px solid ${theme.palette.logo.blue.main}`,
            paddingBottom: theme.spacing(0.5),
        },
        '& $icon': {
            opacity: 1,
        },
    },
    textContent: {
        marginLeft: theme.spacing(1),
        paddingLeft: [0, '!important'],
        paddingRight: [0, '!important'],
    },
    title: {
        fontWeight: 600,
        display: 'block',
        lineHeight: 1,
    },
    description: {
        color: '#7a7a7a',
        display: 'block',
    },
    icon: {
        color: theme.palette.logo.blue.main,
        opacity: 0.5,
    },
}));
