import React from 'react';

import Button from '../button';

// TODO: apply useUser (if possible) after we decided what we should do about the button title (change the component name accordingly)
const StartNowButton = () => {
    return (
        <Button component="a" href="/app/dashboard" variant="contained">
            Start now
        </Button>
    );
};

export default StartNowButton;
