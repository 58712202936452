import React, { useLayoutEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import useResizeAware from 'react-resize-aware';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Link, Typography } from '@material-ui/core';

import PageSection from '$landing-page/components/page-section';

import useStyles from './styles';

const query = graphql`
    query banner {
        site {
            siteMetadata {
                showBanner
                showBannerReadMore
                bannerURL
                bannerText
                bannerColor
            }
        }
    }
`;

const Banner = ({ onHeightChange }) => {
    const currentBannerHeightRef = useRef(null);

    const {
        site: {
            siteMetadata: { showBanner, showBannerReadMore, bannerURL, bannerText, bannerColor },
        },
    } = useStaticQuery(query);
    const classes = useStyles({ bannerColor });
    const [resizeListener, size] = useResizeAware();

    useLayoutEffect(() => {
        if (!onHeightChange) {
            return;
        }
        if (size.height !== currentBannerHeightRef.current) {
            currentBannerHeightRef.current = size.height;
            onHeightChange(size.height);
        }
    }, [onHeightChange, size.height]);

    if (!showBanner) {
        return null;
    }

    return (
        <Box className={classes.banner} component={Link} underline="none" href={bannerURL} rel="noopener">
            {resizeListener}
            <PageSection>
                <Grid spacing={2} container alignItems="center">
                    <Grid item sm xs={12}>
                        <Typography dangerouslySetInnerHTML={{ __html: bannerText }} />
                    </Grid>
                    {showBannerReadMore && (
                        <Grid item>
                            <Typography variant="button">
                                Read More
                                <FontAwesomeIcon icon={['fal', 'long-arrow-right']} className={classes.bannerLinkArrow} />
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </PageSection>
        </Box>
    );
};

Banner.defaultProps = {
    onHeightChange: undefined,
};

Banner.propTypes = {
    onHeightChange: PropTypes.func,
};

export default Banner;
