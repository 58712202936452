import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        position: 'static',
        boxSizing: 'border-box',
        zIndex: theme.zIndex.appBar,
        backfaceVisibility: 'hidden',
    },
    spaceMock: {
        position: 'relative',
        boxSizing: 'border-box',
        background: '#f8faff',
    },
    headerWrapper: {
        background: '#f8faff',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        width: '100%',
        boxSizing: 'border-box',
        zIndex: theme.zIndex.appBar,
    },
    headerWrapperSticky: {
        position: 'fixed',
        top: 0,
        left: 0,
        background: '#f8faff',
        boxSizing: 'border-box',
        boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.1)',
    },
    headerWrapperStickyNarrow: {
        paddingTop: theme.spacing(1.75),
        paddingBottom: theme.spacing(1.75),
    },
    leftOrRightGridItem: {
        width: 184,
    },
    menuIcon: {
        width: 25,
        height: 25,
        color: '#081f4d',
    },
}));
