import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import Link from '$landing-page/components/link';

import { checkMenuItemIsActive } from '../utils';
import useStyles from './styles';

const SubMenu = (props) => {
    const { items, className } = props;

    const classes = useStyles(props);
    const theme = useTheme();
    const isScreenSizeAtLeastMd = useMediaQuery(theme.breakpoints.up('md'));
    const isScreenSizeXs = useMediaQuery(theme.breakpoints.only('xs'));

    let numberOfGridItemCells = 12;
    if (!isScreenSizeXs) {
        const hasLongContent = items.some((item) => item.title.length > 30 || item.description?.length > 50);
        numberOfGridItemCells = hasLongContent ? (isScreenSizeAtLeastMd && 6) || 12 : (isScreenSizeAtLeastMd && 4) || 6;
    }

    return (
        <Grid container spacing={3} className={className}>
            {items.map((item) => {
                const isActive = checkMenuItemIsActive(item);
                return (
                    <Grid
                        key={item.title}
                        item
                        container
                        alignItems="center"
                        component={Link}
                        href={item.url}
                        xs={numberOfGridItemCells}
                        className={clsx(classes.item, {
                            [classes.itemActive]: isActive,
                        })}
                        spacing={2}
                        wrap="nowrap"
                    >
                        {item.icon && (
                            <Grid item>
                                <FontAwesomeIcon
                                    icon={['fad', item.icon]}
                                    className={classes.icon}
                                    size="lg"
                                    swapOpacity={isActive ? item.swapIconOpacity : !item.swapIconOpacity}
                                    fixedWidth
                                />
                            </Grid>
                        )}
                        <Grid item className={classes.textContent}>
                            <Typography variant="body2" color="textPrimary" className={classes.title}>
                                {item.title}
                            </Typography>
                            {item.description && (
                                <Typography variant="caption" className={classes.description}>
                                    {item.description}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

SubMenu.defaultProps = {
    className: undefined,
};

SubMenu.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    className: PropTypes.string,
};

export default SubMenu;
