import React from 'react';
import clsx from 'clsx';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Fade, Grid, Typography } from '@material-ui/core';

import useHover from '$landing-page/hooks/use-hover';

import SubMenu from '../sub-menu';
import { checkMenuItemIsActive } from '../utils';
import useStyles from './styles';

const query = graphql`
    query navigation {
        site {
            siteMetadata {
                menu {
                    title
                    url
                    showSubMenuOnHeader
                    subMenu {
                        title
                        url
                        description
                        icon
                        swapIconOpacity
                    }
                }
            }
        }
    }
`;

const Navigation = () => {
    const classes = useStyles();
    const queryData = useStaticQuery(query);

    const [companyMenuRef, isCompanyMenuHovered] = useHover();
    const [featuresMenuRef, isFeaturesMenuHovered] = useHover();
    const [therapeuticAreasMenuRef, isTherapeuticAreasMenuHovered] = useHover();
    const [companyMenuDropdownRef, isCompanyMenuDropdownHovered] = useHover();
    const [featuresMenuDropdownRef, isFeaturesMenuDropdownHovered] = useHover();
    const [therapeuticAreasMenuDropdownRef, isTherapeuticAreasMenuDropdownHovered] = useHover();

    const { menu } = queryData.site.siteMetadata;
    const menuRefs = [featuresMenuRef, therapeuticAreasMenuRef, undefined, companyMenuRef];
    const menuDropdownRefs = [featuresMenuDropdownRef, therapeuticAreasMenuDropdownRef, undefined, companyMenuDropdownRef];
    const isMenuHovered = [
        isFeaturesMenuHovered || isFeaturesMenuDropdownHovered,
        isTherapeuticAreasMenuHovered || isTherapeuticAreasMenuDropdownHovered,
        undefined,
        isCompanyMenuHovered || isCompanyMenuDropdownHovered,
    ];

    return (
        <Grid container spacing={1} alignItems="center" justify="center" wrap="nowrap" className={classes.root}>
            {menu.map((menuItem, index) => (
                <React.Fragment key={menuItem.title}>
                    <Grid
                        item
                        ref={menuRefs[index]}
                        className={clsx({
                            [classes.menuWithSubMenuWrapper]: menuItem.subMenu,
                        })}
                    >
                        <Typography
                            variant="h6"
                            color="textSecondary"
                            className={clsx(classes.menuItem, {
                                [classes.menuItemHovered]: isMenuHovered[index],
                                [classes.menuItemActive]: checkMenuItemIsActive(menuItem),
                            })}
                            component={menuItem.url ? Link : 'div'}
                            to={menuItem.url || undefined}
                        >
                            {menuItem.title}
                        </Typography>
                        {menuItem.showSubMenuOnHeader !== false && (
                            <div className={classes.menuArrow} hidden={!isMenuHovered[index]} />
                        )}
                    </Grid>
                    {menuItem.showSubMenuOnHeader !== false && (
                        <Fade in={isMenuHovered[index]} ref={menuDropdownRefs[index]}>
                            <div className={classes.subMenuWrapper}>
                                <SubMenu items={menuItem.subMenu} />
                            </div>
                        </Fade>
                    )}
                </React.Fragment>
            ))}
        </Grid>
    );
};

export default Navigation;
