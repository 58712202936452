import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    menuItem: {
        transition: 'all 0.2s',
        padding: theme.spacing(0.5, 2.25),
        borderRadius: 10,
        display: 'block',
        '&:hover, &$menuItemHovered': {
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            backgroundColor: theme.palette.common.white,
        },
    },
    menuItemHovered: {},
    menuItemActive: {
        color: theme.palette.logo.blue.main,
    },
    menuWithSubMenuWrapper: {
        position: 'relative',
    },
    subMenuWrapper: {
        padding: theme.spacing(4),
        position: 'absolute',
        width: 850,
        boxSizing: 'border-box',
        top: 60,
        margin: 'auto',
        borderRadius: 10,
        zIndex: theme.zIndex.drawer,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    },
    menuArrow: {
        content: '""',
        width: 0,
        height: 0,
        position: 'absolute',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: `10px solid ${theme.palette.common.white}`,
        left: 'calc(50% - 10px)',
        top: 50,
        zIndex: theme.zIndex.tooltip,
        filter: 'drop-shadow(0 -3px 2px rgba(0, 0, 0, 0.05))',
    },
}));
