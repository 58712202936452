import { globalHistory } from '@reach/router';

// eslint-disable-next-line import/prefer-default-export
export const checkMenuItemIsActive = (menuItem) => {
    const fullRelativePath = globalHistory.location.pathname + globalHistory.location.hash;
    return (
        fullRelativePath.startsWith(menuItem.url) ||
        menuItem.subMenu?.some((subMenuItem) => fullRelativePath.startsWith(subMenuItem.url))
    );
};
